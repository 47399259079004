import React, {Fragment} from 'react'
import Helmet from 'react-helmet'
import {makeStyles} from "@material-ui/core/styles"
import { graphql, Link } from 'gatsby'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Typography from '@material-ui/core/Typography'
import Layout from '../layouts'
import HeaderNavbar from '../components/HeaderNavbar'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center'
    },
}))

export default function Collection ({ data }) {
    const collections = data.gcms.collections
    const classes = useStyles()

  return (
    <Layout>
      <Helmet>
        <title>Collection | Signxperts - Trade Show Displays, Exhibits, Retail</title>
      </Helmet>
      <HeaderNavbar/>
      <Container maxWidth='md'>
        <Box marginBottom={5} marginTop={3}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link color='inherit' to='/'>Home</Link>
            <Typography color='textPrimary'>
              {collections[0].name}
            </Typography>
          </Breadcrumbs>
        </Box>
        <Grid container spacing={3}>
          {data.gcms.collections.map(({products, slug}, index) => (
              <Fragment key={index}>
                  {products.map(({id, images, name, productSlug}) => (
                      <Grid key={id} item xs={12} sm={4} style={{marginBottom: 40}}>
                          <Link to={productSlug}>
                              <img src={images[0].url} style={{height: 250, objectFit: 'cover', width: '100%'}} alt={name} />
                              <Paper className={classes.paper}>
                                  <Typography variant={'h6'}>
                                      {name}
                                  </Typography>
                              </Paper>
                          </Link>
                      </Grid>
                  ))}
              </Fragment>
          ))}
        </Grid>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String) {
    gcms {
      collections(stage: DRAFT, where: {slug: $slug}) {
        name
        slug
        products {
          id
          name
          images {
            url
          }
          productSlug
          price
        }
      }
    }
  }
`
